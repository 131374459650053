@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;
@tailwind aspect-ratio;

.scrollbar-hidden {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.grayscale {
    filter: grayscale(1);
}